import React, { useEffect, useState } from "react";
import { getStaticPagesData } from "../services/apiServices";
import Header from "../components/Header";
import Footer from "../components/Footer";

function StaticPages() {
	const [termsAndCondition, setTermsAndCondition] = useState(null);
	useEffect(async () => {
		const fn = async () => {
			const result = await getStaticPagesData("termsAndCondition");
			if (result.statusCode == 200) {
				setTermsAndCondition(result.payLoad.result);
			} else {
			}
		};
		fn();
	}, []);
	return (
		<div>
			<Header />
			<div className="px-[10%] py-4 flex items-center justify-start text-[#636363] text-lg bg-[#ededed]">Terms & Conditions</div>
			<div className="px-[10%] pt-2 pb-6">
				<p className="text-sm leading-6 overflow-hidden" dangerouslySetInnerHTML={{ __html: termsAndCondition }} />
			</div>
			<Footer />
		</div>
	);
}

export default StaticPages;
